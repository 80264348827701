import React, { Component } from "react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import AppHeader from "components/main/AppHeader";
import BrowseBhajans from "./components/main/BrowseBhajans";
import BrowsePlaylists from "./components/main/BrowsePlaylists";
import ViewPlaylist from "./components/main/ViewPlaylist";
import { Switch, Route } from "react-router";
import { Router } from "react-router-dom";
import ScrollToTop from "components/util/ScrollToTop";
import Home from "components/main/Home";
import { history } from "util/history";
import ViewNode from "components/nodes/viewNode";
import EditNode from "components/nodes/editNode";
import ConsolidateNode from "components/nodes/consolidateNode";
import EditMarkdownNode from "components/main/EditMarkdownNode";
import ViewNodesInNodeType from "components/admin/viewNodesInNodeType";

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <div className="App">
            <AppHeader />

            <div className="mainContentContainer">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/browse" component={BrowseBhajans} />
                <Route exact path="/playlists" component={BrowsePlaylists} />
                <Route
                  exact
                  path="/playlists/:playlistId"
                  component={ViewPlaylist}
                />
                <Route
                  exact
                  path="/nodeTypes/:nodeTypeId/nodes/:nodeId"
                  component={BrowseBhajans}
                />
                <Route
                  exact
                  path="/nodeTypes/:nodeTypeId"
                  component={ViewNodesInNodeType}
                />
                <Route exact path="/nodes/:nodeId" component={ViewNode} />
                <Route exact path="/nodes/:nodeId/edit" component={EditNode} />
                <Route
                  exact
                  path="/nodes/:nodeId/meanings/edit"
                  component={EditMarkdownNode}
                />
                <Route
                  exact
                  path="/nodeTypes/:nodeTypeId/nodes/:nodeId/edit"
                  component={EditNode}
                />
                <Route
                  exact
                  path="/admin/consolidate"
                  component={ConsolidateNode}
                />
              </Switch>
            </div>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
